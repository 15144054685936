.menuOverlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.menuContent{
    position: fixed;
    top: 0;
    right: -500px;
    width: 90%;
    max-width: 400px;
    height: 100vh;
    background: #fff;
    z-index: 101;
    overflow: auto;
    display: block;
    transition: all ease 300ms;
}

.menuOpen{
    right: 0;
}