html {
  scroll-behavior: smooth;
}

body::before {
  content: "";
  display: block;
  width: 100%;
  height: 30vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#mapa {
  height: 33vw;
  min-height: 80vh;
}

.bg-waves {
  background: url("/bg-waves.jpg");
}

.marker-image {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.htmlContent {
  p {
    margin-top: 16px;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 16px 0 -8px 0;
    font-family: "Merriweather", serif;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 8px 0 -8px 0;
    font-family: "Merriweather", serif;
  }
  ul {
    margin-top: 16px;
    list-style-type: circle;
    padding-left: 20px;

    li {
      margin-top: 8px;
    }
  }
  a{
    color: #295BC8;
    text-decoration: underline;

    &:hover{
      color: #002981;
    }
  }
}

.history-content {
  p {
    margin-bottom: 16px;
  }
}
